import { generateWebDynamicLink } from "@src/app/onboardingStripe/api";
import { getAuthToken } from "@src/app/superagent";
import { environmentConfig } from "@src/appV2/environment/config";
import { useQrCodeDataUrl } from "@src/appV2/lib/utils/useQrCodeDataUrl";
import { TimeRange } from "@src/lib/interface/src/lib/shift";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { compressToEncodedURIComponent } from "lz-string";

import { useRevampWorkplaceSigningFlow } from "../useRevampWorkplaceSigningFlow";

type QRSignatureDataUrlProps = {
  shiftId: string;
  clockInOut: TimeRange;
  lunchInOut?: TimeRange;
  breakAuthorizationReason?: string;
};

export function useQRSignatureDataUrl(props: QRSignatureDataUrlProps) {
  const { firstYesOrNoQuestion, mainQuestion } = useRevampWorkplaceSigningFlow();
  const { data: signoutPageLink = "", isLoading: isTokenLoading } = useQuery({
    queryKey: ["qr-signature-data", props],
    queryFn: async () => {
      const token = await getAuthToken();
      const qrData = compressToEncodedURIComponent(
        JSON.stringify({ ...props, firstYesOrNoQuestion, mainQuestion, token })
      );
      const pageUrl = `${environmentConfig.REACT_APP_ADMIN_WEB_APP_URL}/shift-signout-feedback`;
      return await generateWebDynamicLink(`${pageUrl}?data=${qrData}`);
    },
  });

  const { data: qrCodeDataUrl, isLoading: isQrCodeDataUrlLoading } = useQrCodeDataUrl(
    {
      data: signoutPageLink,
    },
    {
      enabled: !isEmpty(signoutPageLink),
    }
  );

  return {
    qrCodeDataUrl,
    isQrCodeDataUrlLoading: isQrCodeDataUrlLoading || isTokenLoading,
  };
}
