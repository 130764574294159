import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Button, Divider } from "@mui/material";
import { useState } from "react";

import { WorkplaceFeedbackData } from "./types";
import { FeedbackOption, useRevampWorkplaceSigningFlow } from "../useRevampWorkplaceSigningFlow";

type WorkplaceFeedbackQuestionProps = {
  onSubmit: (data: WorkplaceFeedbackData) => void;
  onFeedbackDataUpdate: (data: WorkplaceFeedbackData) => void;
  feedbackData?: WorkplaceFeedbackData;
};

export function WorkplaceFeedbackQuestion(props: WorkplaceFeedbackQuestionProps) {
  const { onFeedbackDataUpdate, onSubmit, feedbackData } = props;
  const [taskCompleted, setTaskCompleted] = useState<string>();
  const [performance, setPerformance] = useState<FeedbackOption>();
  const [showError, setShowError] = useState<boolean>(false);
  const { firstYesOrNoQuestion, mainQuestion } = useRevampWorkplaceSigningFlow();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {/* Task completion */}
      <Box>
        <Text mb={1}>{firstYesOrNoQuestion}</Text>
        <Box display="flex" gap={1}>
          {["Yes", "No"].map((label) => (
            <Button
              key={label}
              variant={taskCompleted === label ? "contained" : "outlined"}
              color="primary"
              fullWidth
              onClick={() => {
                setTaskCompleted(label);
                setPerformance(undefined);
                setShowError(false);
                if (isDefined(feedbackData)) {
                  onFeedbackDataUpdate({
                    firstResponse: label,
                  });
                }
              }}
              size="small"
              sx={{ px: 0 }}
            >
              {label}
            </Button>
          ))}
        </Box>
      </Box>

      {/* Performance assessment */}
      <Divider />
      <Box>
        <Text mb={1} variant="subtitle1">
          {mainQuestion.description}
        </Text>
        <Box display="flex" flexDirection="column" gap={1}>
          {mainQuestion.options.map(({ value, type }) => (
            <Button
              key={value}
              variant={performance?.value === value ? "contained" : "outlined"}
              color="primary"
              fullWidth
              onClick={() => {
                setPerformance({ value, type });
                setShowError(false);
                if (isDefined(feedbackData)) {
                  onFeedbackDataUpdate({
                    secondResponse: value,
                    secondResponseType: type,
                  });
                }
              }}
              size="small"
            >
              {value}
            </Button>
          ))}
        </Box>
      </Box>

      {showError && (
        <Text color="error" mt={1} variant="body2">
          *Please select an option
        </Text>
      )}

      <Text variant="body2" color="text.secondary">
        Your response is shared with the administrators and staffing coordinators at your facility.
      </Text>

      {!isDefined(feedbackData) && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ py: 1.5, mb: 2 }}
          onClick={() => {
            if (!isDefined(taskCompleted) || !performance) {
              setShowError(true);
              return;
            }
            onSubmit({
              firstQuestion: firstYesOrNoQuestion,
              firstResponse: taskCompleted,
              secondQuestion: mainQuestion.description,
              secondResponse: performance?.value,
              secondResponseType: performance?.type,
            });
          }}
        >
          Continue
        </Button>
      )}
    </Box>
  );
}
